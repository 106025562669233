.md-editor
  display: block
  border: 1px solid #ddd
  > .md-header, .md-footer
    display: block
    background: #fff
    padding: 6px 4px
  >
  .md-header
    margin: 0
    .md-preview
      background: #fff
      border-top: 1px dashed #ddd
      border-bottom: 1px dashed #ddd
      min-height: 10px
      overflow: auto
    textarea
      font-family: Monaco,Menlo,Consolas,"Courier New",monospace
      font-size: 14px
      outline: thin dotted
      display: block
      width: 100%
      border: 0
      border-top: 1px dashed #ddd
      border-bottom: 1px dashed #ddd
      border-radius: 0
      box-shadow: none
      background: #eee
      margin: 0
      padding: 0
      &:focus
        box-shadow: none
        background: #fff
  &.active
    outline: 0
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)
    border-color: #66afe9
