@include media-breakpoint-down(xs) {
  .navbar > .container {
    max-width: none;
  }

  .navbar .logo {
    display: none;
  }

  .navbar-tabs .nav-item:first-child a {
    padding-left: 0;
  }
}

@include media-breakpoint-down(sm) {
  .navbar .username {
    display: none
  }
}

@include media-breakpoint-down(md) {
  .hidden-md-down {
    display: none;
  }

  .night_mode_toggler {
    display: none;
  }

  .navbar-tabs .nav-item {
    padding: 8px 0;
  }

  .navbar-nav .open .notifications {
    width: 298px;
  }

  .navbar-nav .open .user_menu {
    width: 298px;
    margin-left: -49%;
  }

  .navbar-nav .open .login_menu {
    width: 200%;
    padding: 0 15px;
  }

  .navbar-form .checkbox, .navbar-form .radio {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
  }

  .navbar-form .checkbox label, .radio label {
    color: #D6D6D6;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
    color: #DEE9F1;
  }

  .notifications_list a {
    width: 100%;
  }

  .user_menu .glyphicon {
    color: #BDBDBD;
  }

  .user_menu .divider {
    background-color: #606C79;
  }

  .comment .comment_actions, .entry .entry_actions {
    visibility: visible;
  }
}

@media (max-width: 768px) {


}

@media (max-width: 400px) {

  .main_col {
    padding-left: 3px;
    padding-right: 3px;
  }

  /* contents */

  .content .voting {
    width: 60px;
  }

  .content .img-thumbnail {
    width: 50px;
    margin-right: 0;
  }

  /* entries */

  .entry .entry_avatar img {
    height: 30px;
    width: 30px;
  }

  .entry_header {
    margin-left: 36px;
  }

  .entry_header .fa-clock-o, .entry_header time {
    display: none;
  }

  .entry_header .pull-right {
    font-size: 90%;
  }

  .entry_header .pull-right > .glyphicon {
    display: none;
  }

  .entry_text {
    margin-left: 36px;
  }

  .entry_reply {
    margin-left: 15px;
  }
}

