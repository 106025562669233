.content_sort .selected, .content_filter .selected
  font-weight: bold

.content
  position: relative
  padding-bottom: 5px
  overflow: visible
  float: left
  width: 100%
  flex-wrap: wrap
  .voting
    float: left
    width: 70px
    min-height: 1px
  .img-thumbnail
    width: 100px
    margin-right: 10px
    padding: 0
    border: none
    border-radius: 4px
    transition: none
    &.refreshing
      font-size: 50px
      padding: 8px 0
      text-align: center
  .btn-default
    color: #333
    background-color: #FAFAFA
    border-color: #F0F0F0
  .btn-success
    color: #FFF
    background-color: #5CB85C
    border-color: #4CAE4C
  .btn-danger
    color: #FFF
    background-color: #D9534F
    border-color: #D43F3A
  .vote-btn-up
    margin-bottom: 5px
  .content_head
    a
      color: #2D72BD
  .content_desc
    font-size: 13px
    small
      font-size: 12px
  .content_actions
    display: none
    position: absolute
    bottom: 0
    right: 10px
    font-size: 13px
    a
      margin-left: 8px
  &:hover
    .content_actions
      display: block
  .summary
    margin-top: 3px
    i.fa
      color: lightgray
      margin-right: 3px
      margin-left: 10px
      &:first-of-type
        margin-left: 0px
  .nsfw
    color: #E93939
    font-size: 10px
    vertical-align: super
  .eng
    color: #8F8F8F
    font-size: 10px
    vertical-align: super

.vote-up
  color: #2AA52A !important

.vote-down
  color: #DB5E5E !important

.content .vote-btn-up, .content .vote-btn-down
  width: 50px
  font-size: 14px
  padding: 3px 5px

.content h2, .content h1
  font-size: 18px

.content_preview_link
  cursor: pointer
  background: #f8f8f8
  padding: 2px 5px
  margin-right: 10px

.content_preview
  margin: 10px 0 25px 70px
  clear: both
  width: 100%

.content_preview img, .content_preview video
  max-width: 100%

.related_link
  width: 360px
  display: inline-block
  vertical-align: top
  margin-bottom: 12px
  .voting
    float: left
    width: 50px
  .vote-btn-up
    margin-bottom: 5px
  .glyphicon-trash
    font-size: 12px
    color: #7E7E7E
    cursor: pointer
    margin-left: 5px
    background: #E7E7E7
    padding: 2px 3px 3px 2px
    border-radius: 2px
    border: 1px solid #D8D8D8
  .nsfw
    color: #E93939
    font-size: 10px
    vertical-align: super
  .info
    font-size: 12px
  .img-thumbnail
    max-width: 70px
    border: 0
    padding: 0
    margin-right: 8px
