footer
  color: #7E7E7E
  max-width: 1170px
  margin: 0 auto
  padding-left: 0.9375rem
  padding-right: 0.9375rem

  ul
    list-style-type: none
    margin-bottom: 0

  .social-links
    float: right
    margin-top: -6px
    margin-left: 25px
    font-size: 22px
    a
      margin-left: 5px
    a:hover, a:active
      text-decoration: none
    i.fa-facebook-square
      color: #3b5998
    i.fa-twitter-square
      color: #1da1f2
    i.fa-github-square
      color: #333
