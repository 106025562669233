.groupbar
  width: 100%
  height: 26px
  color: #93a1a1
  font-size: 12px
  position: relative
  background: #0d0e0f
  border-bottom: 1px solid #1d2023
  a
    color: #8f8f8f
  .caret
    border-top: 4px solid #C2C2C2
  .dropdown-menu
    padding: 5px
    height: inherit
    overflow: visible
    position: absolute
    top: 23px
    border-radius: 0 0 4px 4px
    max-width: 816px
    z-index: 1500
    > li
      font-size: 11px
      float: none
      margin: 1px
      width: 199px
      break-inside: avoid
      page-break-inside: avoid
      -webkit-column-break-inside: avoid
  > ul
    list-style: none
    padding: 4px 10px
    height: 20px
    overflow: hidden
  ul > li
    margin-right: 10px
    float: left
  .group_list_link
    position: absolute
    right: -9px
    background: #202329
    text-align: center
    top: 0
    padding: 4px
    width: 100px
    height: 100%

.groupbar-fixed-top
  position: fixed
  z-index: 1020
  top: 0
  left: 0

