body.night
  background-color: #252525
  color: #CACACA

  hr
    border-top: 1px solid #6D6D6D

  code
    color: #D8486D
    background-color: #3A3A3A

  .navbar
    border-bottom: 1px solid #000
    box-shadow: none

  .navbar
    background: #1c1c1c

  .navbar .navbar-nav > .active > a,
  .navbar .navbar-nav > .active > a:hover,
  .navbar .navbar-nav > .active > a:focus
    color: #DDD
    background-color: #353535

  .navbar-inverse .navbar-nav > li
    border-right: 1px solid #161616

  .navbar-inverse .navbar-nav > li:first-child
    border-left: 1px solid #161616

  .navbar .navbar-nav .active a,
  .navbar .navbar-nav .active a:hover,
  .navbar .navbar-nav .active a:focus
    color: #fff
    background-color: #131313

  .card
    background-color: #444

  .card-header,
  .card-footer
    background-color: #3b3b3b

  .dropdown-menu
    color: #b7babc
    background-color: #424242

  .dropdown-menu > a
    color: #A0A0A0

  .dropdown-divider
    background-color: #5F5F5F

  .dropdown-item:hover
    background-color: #4f4f4f

  .notifications_list a
    color: #8FBFDA
    background-color: #424242
    border-top: 1px solid #525252

  .notifications_list a:nth-child(2n+2)
    background-color: #3C3C3C

  .notifications_list a.new
    color: #D6D6D6
    background: #5F5E4C

  .notifications_footer
    background: #303030
    color: #587DAC
    border-top: 1px solid #4B4B4B

  .groupbar
    background: #0a0a0a

  .groupbar a
    color: #979797

  & > .container
    background: #333

  a
    color: #96BADA

  .modal-content
    background-color: #424242

  .well
    background-color: #444
    border: 1px solid #575757

  .form-control, .bootstrap-tagsinput
    background-color: #636363
    border: 1px solid #757575
    color: #BBB

  .btn-default, .btn-secondary
    border-color: #818181
    color: #D8D8D8
    background-color: #5A5A5A

  .btn-light
    color: #a6a6a6
    background-color: #424242
    border-color: #575757

  .btn-success
    color: #FFF
    background-color: #5CB85C
    border-color: #4CAE4C

  .btn-danger
    color: #FFF
    background-color: #D9534F
    border-color: #D43F3A

  .pagination > li > a,
  .pagination > li > span
    background-color: #4D4D4D
    border: 1px solid #696969

  .pagination > .disabled > span,
  .pagination > .disabled > a,
  .pagination > .disabled > a:hover,
  .pagination > .disabled > a:focus
    color: #999
    cursor: not-allowed
    background-color: #303030
    border-color: #5F5F5F

  .panel
    background-color: #414141

  .panel-default
    border-color: #4D4D4D

  .panel-default > .panel-heading
    color: #B3B3B3
    background-color: #414141
    border-color: #535353

  .panel-footer
    background-color: #3A3A3A
    border-top: 1px solid #555

  .popover
    background-color: #292929

  .tt-dropdown-menu
    background-color: #797979

  .list-group-item
    background-color: #3F3F3F
    border: 1px solid #5C5C5C

  a.list-group-item
    color: #B3B1B1

  a.list-group-item .list-group-item-heading
    color: #C0C0C0

  a.list-group-item:focus,
  a.list-group-item:hover
    background-color: #585858

  .nav-tabs>li.active > a,
  .nav-tabs>li.active > a:focus,
  .nav-tabs>li.active > a:hover
    color: #E7E7E7
    background: #333

  .content .btn-default
    color: #919191
    background-color: #1B1B1B
    border-color: #3C3C3C

  .content .btn-success
    color: #FFF
    background-color: #5CB85C
    border-color: #4CAE4C

  .content .btn-danger
    color: #FFF
    background-color: #D9534F
    border-color: #D43F3A

  .content .content_head a
    color: #78A0CA

  .content .img-thumbnail
    border: 1px solid #242424
    background-color: #3C3C3C

  .content_preview_link
    background: #505050

  .entry_reply,
  .comment_reply
    background: #2B2B2B

  .comment.highlighted,
  .entry.highlighted
    background: #63635B

  .entry_expand_replies
    background: #4D4D4D
    border-top: 1px solid #838383
    border-bottom: 1px solid #838383
    color: #A5A5A5

  .md .show_spoiler
    background: #474747

  .comment_parent
    background: #494949

  .editor-toolbar a
    color: #7A98B6!important

  .label-default
    background-color: #535353

  .table
    color: #CACACA

  .table tbody>tr>td, .table tbody>tr>th,
  .table tfoot>tr>td, .table tfoot>tr>th,
  .table thead>tr>td, .table thead>tr>th
    border-top: 1px solid #5E5E5E

  .table thead>tr>th
    border-bottom: 2px solid #707070

  thead .glyphicon
    color: rgba(255, 255, 255, 0.33)

  .CodeMirror-gutters
    border-right: 1px solid #585858
    background-color: #3D3C3C

  .cm-s-default span.cm-comment
    color: #95958a

  .cm-s-default span.cm-atom
    color: #00a8c6

  .cm-s-default span.cm-number
    color: #00a8c6

  .cm-s-default span.cm-property,
  .cm-s-default span.cm-attribute
    color: #9ddfe9

  .cm-s-default span.cm-keyword
    color: #ffb928

  .cm-s-default span.cm-string
    color: #ffcf6c

  .cm-s-default span.cm-variable
    color: #ffffec

  .cm-s-default span.cm-variable-2
    color: #00a8c6

  .cm-s-default span.cm-def
    color: #ffffec

  .cm-s-default span.cm-bracket
    color: #fffffc
    font-weight: bold

  .cm-s-default span.cm-tag
    color: #9ddfe9

  .cm-s-default span.cm-link
    color: #f54b07

  .cm-s-default span.cm-error
    background: #636363
    color: #ffffec
