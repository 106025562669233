.navbar
  min-height: 54px
  background: #202329

  &.navbar-fixed-top
    position: fixed
    top: 26px
    z-index: 1010
    width: 100%

.navbar-tabs
  flex: 1

.navbar-right
  order: 2

.navbar-toggler
  order: 3

.navbar
  .navbar-nav > li > a
    color: #DADADA
    padding: 9px 15px
    .active > a
      color: #fff
      background-color: #161719
      &:hover, &:focus
        color: #fff
        background-color: #161719
    .open > a
      color: #fff
      &:hover, &:focus
        color: #fff
  .open .login_menu
    padding: 12px
    width: 250px

.navbar-nav
  flex-direction: row

.navbar-nav .dropdown-menu
  position: absolute

@include media-breakpoint-up(md)
  .navbar img
    margin-right: 20px
