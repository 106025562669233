.comment
  margin: 0 0 15px
  border-radius: 0
  box-shadow: none
  border: none

.comment_reply
  margin-left: 60px
  background: #FCFCFC
  form .btn
    margin-bottom: 10px

.comment
  .comment_avatar
    float: left
    position: relative
    overflow: hidden
    img
      height: 50px
      width: 50px
  .comment_actions
    visibility: hidden
    position: relative
    bottom: 10px
    right: 15px
    font-size: 13px
    a
      margin-left: 8px
  &:hover .comment_actions
    visibility: visible

.comment_header
  font-size: 13px
  margin: 2px
  padding: 5px 10px
  margin: 2px 2px 2px 56px

.comment .voting
  position: relative
  margin-left: 10px
  top: -2px

.comment_text
  padding: 5px 10px 8px
  margin-left: 56px
  font-size: 13px

.comment i.fa
  &:first-of-type
    margin-left: 0px
  color: #c0c0c0
  margin-right: 1px
  margin-left: 8px
