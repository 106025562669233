.twitter-typeahead {
  width: 100%;
  position: relative;
}

.twitter-typeahead .tt-query,
.twitter-typeahead .tt-hint {
  margin-bottom: 0;
  width: 100%;
  height: 34px;
  position: absolute;
  top: 0;
  left: 0;
}

.twitter-typeahead .tt-hint {
  color: #a1a1a1;
  padding: 6px 12px;
  border: 1px solid transparent;
  font-size: 14px;
}

.twitter-typeahead .tt-query {
  z-index: 2;
  border-radius: 4px !important;
}

.input-group-addon + .twitter-typeahead > .tt-query {
  border-top-left-radius: 0!important;
  border-bottom-left-radius: 0!important;
}

.input-group-appended > .twitter-typeahead > .tt-query {
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
}

.tt-menu {
  min-width: 250px;
  margin-top: 2px;
  padding: 5px 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  background-clip: padding-box;
}

.tt-suggestion {
  display: block;
  padding: 3px 20px;
  cursor: pointer;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0081c2;
  background-image: linear-gradient(to bottom, #0088cc, #0077b3);
  background-repeat: repeat-x;
}

.tt-suggestion:hover a {
  color: #fff;
}

.twitter-typeahead p {
  margin: 0;
}

.twitter-typeahead img.avatar {
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 10px;
}

.twitter-typeahead .count {
  color: #B6B6B6;
  font-size: 90%;
  vertical-align: top;
  margin-left: 8px;
}
